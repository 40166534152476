import React, { useState } from 'react';
import List from './List';
import Toast from './Toast';
import firebase from '../firebase';

function ConnectionRequests(props) {
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState('');

  const handleCancelInvitation = (currentUserId, invitedUserId) => {
    const database = firebase.database();

    const ref = database.ref(
      `users/${currentUserId}/connectionRequests/sent/${invitedUserId}`
    );
    ref.remove();
    setShowToast(true);
    setToastMessage('Invite Removed!');
    setToastType('success');
    setTimeout(() => setShowToast(false), 3000);

    const reverseRef = database.ref(
      `users/${invitedUserId}/connectionRequests/received/${currentUserId}`
    );
    reverseRef.remove();
  };

  const getConnectionRequestResults = props => {
    if (props.requests && Object.keys(props.requests).length) {
      const listItems = Object.values(props.requests).map(obj => {
        return {
          title: `${obj.firstName} ${obj.lastName}`,
          subtitle: 'Tap to cancel request.',
          icon: 'fas fa-user-circle',
          handleClick: () => {
            handleCancelInvitation(props.userId, obj.uid);
          }
        };
      });
      return (
        <div className="search-results search-list">
          <List listItems={listItems} />
        </div>
      );
    } else {
      return (
        <div className="content-boxed bottom-50">
          <div className="content">
            <h3 className="bolder">
              No Sent Requests. Go to search to find and invite friends.
            </h3>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      {getConnectionRequestResults(props)}
      <Toast message={toastMessage} visible={showToast} type={toastType} />
    </div>
  );
}

export default ConnectionRequests;
