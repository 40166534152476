import React from 'react';
import Header from '../components/Header';
import ActionSheet from '../components/ActionSheet';
import { Link } from 'react-router-dom';
import firebase from 'firebase';
import * as firebaseui from 'firebaseui';

const ui = new firebaseui.auth.AuthUI(firebase.auth());

class Login extends React.Component {
  state = {
    email: '',
    password: '',
    errorMessage: '',
    isActionSheetOpen: false
  };

  handleChange = evt => {
    const { name, value } = evt.target;
    this.setState({
      [name]: value
    });
  };

  promptUserForPassword = () => {
    this.setState({
      isActionSheetOpen: true
    });
  };

  handleFBlogin = () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async result => {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const token = result.credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        const email = user.email;
        const userId = user.uid;
        if (userId) {
          const ref = firebase.database().ref(`users/${userId}`);
          const snap = await ref.once('value');
          if (!snap.val || !snap.val()) {
            const invitationsRef = firebase.database().ref('invitations');
            const snapshot = await invitationsRef
              .orderByChild('requestee')
              .equalTo(email.toLowerCase())
              .once('value');
            const connectionRequests = {
              received: {}
            };
            const val = snapshot.val();
            if (val) {
              Object.keys(val).forEach(key => {
                const requestor = val[key].requestor;
                connectionRequests.received[requestor] = true;
                firebase
                  .database()
                  .ref(`users/${requestor}/connectionRequests/sent`)
                  .update({
                    [userId]: true
                  });

                firebase
                  .database()
                  .ref(`users/${requestor}/invitations`)
                  .orderByChild('email')
                  .equalTo(email)
                  .once('value')
                  .then(snapshot => {
                    if (snapshot.val()) {
                      Object.keys(snapshot.val()).forEach(key => {
                        firebase
                          .database()
                          .ref(`users/${requestor}/invitations/${key}`)
                          .remove();
                      });
                    }
                  });

                firebase
                  .database()
                  .ref(`invitations/${key}`)
                  .remove();
              });
            }

            ref.set({
              email: user.email.toLowerCase(),
              firstName: user.displayName,
              lastName: '',
              uid: user.uid,
              connectionRequests
            });
          }
        }
      })
      .catch(error => {
        if (error.code === 'auth/account-exists-with-different-credential') {
          // Step 2.
          // User's email already exists.
          // The pending Facebook credential.
          this.pendingCred = error.credential;
          // The provider account's email address.
          this.email = error.email;
          // Get sign-in methods for this email.
          firebase
            .auth()
            .fetchSignInMethodsForEmail(this.email)
            .then(methods => {
              // Step 3.
              // If the user has several sign-in methods,
              // the first method in the list will be the "recommended" method to use.
              if (methods[0] === 'password') {
                // Asks the user their password.
                // In real scenario, you should handle this asynchronously.
                // var password = promptUserForPassword(); // TODO: implement promptUserForPassword.
                this.promptUserForPassword();
                return;
              }
            });
        }
      });
  };

  handleUpdatePasswordCred = evt => {
    this.password = evt.target.value;
  };

  handleLogin = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then(cred => {})
      .catch(error => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        this.setState({
          errorMessage
        });
      });
  };

  componentDidMount() {
    // ui.start('#firebaseui-auth-container', firebaseConfig.uiConfig);
  }

  getSheetContent = () => {
    return (
      <div>
        <h3 className="uppercase ultrabold top-30 bottom-30">
          Enter Your Password to Continue
        </h3>

        <p className="bottom-5">
          <label htmlFor="passwordCred">
            <i className="fa fa-1x fa-users right-10" />
            Password
          </label>
        </p>

        <div className="search-box search-color bg-dark1-dark shadow-tiny round-huge bottom-20">
          <input
            id="passwordCred"
            type="password"
            onChange={this.handleUpdatePasswordCred}
          />
        </div>
        <button
          style={{ display: 'block' }}
          className="button button-m shadow-small button-circle bg-red1-dark top-20"
          onClick={this.handleActionSheetClose}
        >
          Enter
        </button>
      </div>
    );
  };

  handleActionSheetClose = () => {
    this.setState({
      isActionSheetOpen: false
    });
    firebase
      .auth()
      .signInWithEmailAndPassword(this.email, this.password)
      .catch(error => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        this.setState({
          errorMessage
        });
      })
      .then(user => {
        // Step 4a.
        // debugger;
        return user.user.linkWithCredential(this.pendingCred);
      })
      .then(() => {
        // Facebook account successfully linked to the existing Firebase user.
        //goToApp();
      });
  };

  render() {
    return (
      <div>
        <Header
          title="Get Grub"
          avatar={false}
          bannerOnly={true}
          removeSettings={true}
          removeLogout={true}
        />
        <div className="content-boxed left-40 right-40 top-60">
          <div className="content top-10 bottom-20">
            <Link
              to="/register"
              style={{ textAlign: 'center' }}
              className="font-16 color-theme opacity-50 bottom-10"
            >
              <span style={{ textAlign: 'center' }}>Create Account</span>
            </Link>
            <img
              style={{ margin: '0 auto' }}
              alt="logo"
              src="images/logos/logo-transparent-med.png"
            />
            <h1 className="center-text uppercase ultrabold fa-3x">LOGIN</h1>
            <p className="center-text font-11 under-heading bottom-30 color-highlight">
              Let's get you logged in
            </p>
            <div className="input-style has-icon input-style-1 input-required">
              <i className="input-icon fa fa-user font-11" />
              <span className="input-style-1-inactive">Email</span>
              <em>(required)</em>
              <input
                type="email"
                name="email"
                placeholder="Email"
                onChange={this.handleChange}
              />
            </div>
            <div className="input-style has-icon input-style-1 input-required">
              <i className="input-icon fa fa-lock font-11" />
              <span>Password</span>
              <em>(required)</em>
              <input
                onChange={this.handleChange}
                type="password"
                name="password"
                placeholder="Password"
              />
            </div>
            <div className="clear" />
            <button
              onClick={this.handleLogin}
              className="button button-full button-m shadow-large button-round-small bg-green1-dark top-30 bottom-0"
            >
              LOGIN
            </button>
            <div className="firebaseui-error-wrapper">
              <p className="firebaseui-error firebaseui-text-input-error color-red2-dark">
                {this.state.errorMessage}
              </p>
            </div>
            <div className="divider top-30" />
            <button
              style={{ position: 'relative' }}
              className="back-button button button-icon button-full button-xs shadow-large button-round-small font-11 bg-facebook top-30 bottom-0"
              onClick={this.handleFBlogin}
            >
              <i
                style={{ lineHeight: '26px' }}
                className="fab fa-facebook-f"
              ></i>
              Sign in with Facebook
            </button>
            <div className="divider top-30" />
            <div className="one-half">
              <Link to="/register" className="font-11 color-theme opacity-50">
                <span>Create Account.</span>
              </Link>
            </div>
            <div className="one-half last-column">
              <Link
                to="/forgot-password"
                className="text-right font-11 color-theme opacity-50"
              >
                Forgot Credentials
              </Link>
            </div>
            <div className="clear" />
            <p>
              By using our site, you agree to our{' '}
              <a href="https://app.termly.io/document/terms-of-use-for-website/1834d101-9d7a-4df3-ac84-93e94c9e4ca4">
                Terms of Service
              </a>{' '}
              and our{' '}
              <a href="https://app.termly.io/document/privacy-policy/6ef15243-8469-4ecb-92e2-790b53e665b0">
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </div>
        <ActionSheet
          isOpen={this.state.isActionSheetOpen}
          content={this.getSheetContent()}
          handleClose={this.handleActionSheetClose}
        />
      </div>
    );
  }
}

export default Login;
