import React from 'react';
import FooterMenu from '../components/FooterMenu';
import Header from '../components/Header';
import List from '../components/List';
import Toast from '../components/Toast';
import firebase from '../firebase';

class Search extends React.Component {
  state = {
    searchValue: '',
    hasSearched: false,
    isLoading: false,
    showToast: false,
    toastMessage: '',
    toastType: 'success',
    data: []
  };

  handleOnChange = evt => {
    this.setState({
      searchValue: evt.target.value
    });
  };

  handleSearch = async () => {
    await this.setState({
      isSearching: true,
      data: []
    });

    const findUserByEmail = firebase
      .functions()
      .httpsCallable('findUserByEmail');

    findUserByEmail({
      email: this.state.searchValue.toLowerCase(),
      friendsList: this.props.friendsList,
      userData: this.props.userData
    }).then(data => {
      if (data.data) {
        this.setState({
          data: data.data,
          isSearching: false,
          hasSearched: true
        });
      } else {
        this.setState({
          isSearching: false,
          hasSearched: true
        });
      }
    });
    // const database = firebase.database();
    // const userRef = database.ref('users');
    // const snapshot = await userRef
    //   .orderByChild('email')
    //   .equalTo(this.state.searchValue.toLowerCase())
    //   .once('value');
    // if (snapshot.val()) {
    //   console.log(Object.values(snapshot.val()));
    //   const data = Object.values(snapshot.val()).map(obj => {
    //     const connected = this.props.friendsList.some(friend => {
    //       return obj.uid === friend.uid;
    //     });
    //     let connectionRequestSent = false;
    //     if (
    //       this.props.userData.connectionRequests &&
    //       this.props.userData.connectionRequests.sent &&
    //       this.props.userData.connectionRequests.sent[obj.uid]
    //     ) {
    //       connectionRequestSent = true;
    //     }
    //     return {
    //       firstName: obj.firstName,
    //       lastName: obj.lastName,
    //       connected,
    //       connectionRequestSent,
    //       uid: obj.uid
    //     };
    //   });
    //   this.setState({
    //     data,
    //     isSearching: false,
    //     hasSearched: true
    //   });
    // } else {
    //   this.setState({
    //     isSearching: false,
    //     hasSearched: true
    //   });
    // }
  };

  handleAddFriend = uid => {
    const userConnections = this.props.userData.connectionRequests;
    if (userConnections && userConnections.sent && userConnections.sent[uid]) {
      window.alert('You have already sent a request to this user.');
      return;
    }
    const database = firebase.database();
    const userConnectionRequestsRef = database.ref(
      `/users/${this.props.userId}/connectionRequests/sent/`
    );
    userConnectionRequestsRef.update({
      [uid]: true
    });
    const userConnectionRequestsReceivedRef = database.ref(
      `/users/${uid}/connectionRequests/received/`
    );
    userConnectionRequestsReceivedRef.update({
      [this.props.userId]: true
    });
    const data = this.state.data.map(obj => {
      obj.connectionRequestSent = true;
      return obj;
    });
    this.setState(
      {
        data,
        showToast: true,
        toastMessage: 'Request Sent!',
        toastType: 'success'
      },
      () => {
        setTimeout(() => this.setState({ showToast: false }), 3000);
      }
    );
  };

  handleInviteUser = async () => {
    let hasUserBeenInvited = false;
    let hasUserBeenInvitedByCurrentUser = false;
    const database = firebase.database();
    const invitationsRef = database.ref('invitations');
    const userRef = database.ref(`/users/${this.props.userId}/invitations/`);
    const snapshot = await invitationsRef
      .orderByChild('requestee')
      .equalTo(this.state.searchValue)
      .once('value');
    if (snapshot.val()) {
      hasUserBeenInvited = true;
      hasUserBeenInvitedByCurrentUser = Object.values(snapshot.val()).some(
        obj => {
          return obj.requestor === this.props.userId;
        }
      );
    }

    if (hasUserBeenInvited) {
      if (!hasUserBeenInvitedByCurrentUser) {
        invitationsRef.push().set({
          requestee: this.state.searchValue,
          requestor: this.props.userId
        });
        userRef.push().set({
          email: this.state.searchValue
        });
      }
      this.setState(
        {
          showToast: true,
          toastMessage: 'User has been invited.',
          toastType: 'success'
        },
        () => {
          setTimeout(() => this.setState({ showToast: false }), 3000);
        }
      );
    } else {
      const inviteFriend = firebase.functions().httpsCallable('inviteFriend');
      inviteFriend({
        toInvite: this.state.searchValue,
        userId: this.props.userId,
        userName: `${this.props.userData.firstName} ${this.props.userData.lastName}`
      })
        .then(result => {
          this.setState(
            {
              showToast: true,
              toastMessage: 'Email Sent!',
              toastType: 'success'
            },
            () => {
              setTimeout(() => this.setState({ showToast: false }), 3000);
            }
          );
          invitationsRef.push().set({
            requestee: this.state.searchValue,
            requestor: this.props.userId
          });
          userRef.push().set({
            email: this.state.searchValue
          });
        })
        .catch(error => {
          // Getting the Error details.
          this.setState(
            {
              showToast: true,
              toastMessage: 'An error occurred',
              toastType: 'error'
            },
            () => {
              setTimeout(() => this.setState({ showToast: false }), 3000);
            }
          );
          console.log(error);
        });
    }
  };

  getResults = listItems => {
    if (this.state.data.length) {
      return (
        <div className="search-results search-list">
          <List listItems={listItems} />
        </div>
      );
    } else if (this.state.hasSearched) {
      return (
        <div>
          <div className="content-boxed bottom-50">
            <div className="content">
              <h3 className="bolder">No Results</h3>
              <button
                className="button button-m shadow-small button-circle bg-red1-dark top-20"
                onClick={this.handleInviteUser}
              >
                Invite {this.state.searchValue} to Group Grubblr
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return '';
    }
  };

  render() {
    const listItems = this.state.data.map(item => {
      const obj = {};
      obj.title = `${item.firstName} ${item.lastName}`;
      obj.subtitle = item.connected
        ? 'You are already friends.'
        : 'Tap to send a friend request.';
      obj.subtitle = item.connectionRequestSent
        ? 'A connection request has been sent'
        : obj.subtitle;
      obj.icon = 'fas fa-user';
      if (!item.connected && !item.connectionRequestSent) {
        obj.handleClick = () => {
          this.handleAddFriend(item.uid);
        };
        obj.icon = 'fas fa-user-plus';
      }
      return obj;
    });
    return (
      <div>
        <div className="page-content header-clear-medium">
          <Header title="Search" subtitle="Find a Friend" bg={22} />
          <div className="content-boxed">
            <p className="content bottom-25">
              Enter an email address to connect with a friend.
            </p>
          </div>
          <div className="content top-40">
            <div className="search-box search-color bg-dark1-dark shadow-tiny round-huge bottom-0">
              <i className="fa fa-search" />
              <input type="text" onChange={this.handleOnChange} />
            </div>
            <button
              className="button button-m shadow-small button-circle bg-red1-dark top-20"
              onClick={this.handleSearch}
              disabled={this.state.searchValue === '' ? true : false}
            >
              Search
            </button>

            {this.state.isLoading ? (
              <Toast message="Loading" visible={true} type="inProgress" />
            ) : (
              this.getResults(listItems)
            )}
          </div>
        </div>
        <Toast
          message={this.state.toastMessage}
          visible={this.state.showToast}
          type={this.state.toastType}
        />
      </div>
    );
  }
}

export default Search;
