import React, { useState } from 'react';
import List from './List';
import Toast from './Toast';
import firebase from '../firebase';
import uuidv1 from 'uuid/v1';
import DatePicker from 'react-datepicker';
import './DatePickerOR.css';

import 'react-datepicker/dist/react-datepicker.css';

function CreateOuting(props) {
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState('');
  const [selected, setSelected] = useState([]);
  const [outingName, setOutingName] = useState('');
  const [duration, setDuration] = useState('900000');
  const [outingDate, setDate] = useState(new Date());

  const selectRestaurant = index => {
    let newSelected = selected.slice();
    newSelected.push(index);
    setSelected(newSelected);
  };

  const updateDate = date => {
    setDate(date);
  };

  const deselectRestaurant = index => {
    let newSelected = [];
    selected.forEach(num => {
      if (num !== index) {
        newSelected.push(num);
      }
    });

    setSelected(newSelected);
  };

  const getResults = props => {
    if (props.businesses && props.businesses.length) {
      const listItems = props.businesses.map((obj, index) => {
        let subtitle = 'Tap to include in voting.';
        let icon = 'fas fa-times color-red1-dark';
        let handleClick = () => {
          selectRestaurant(index);
        };
        if (selected.indexOf(index) !== -1) {
          subtitle = 'Tap to remove from voting.';
          icon = 'fas fa-check color-green1-light';
          handleClick = () => {
            deselectRestaurant(index);
          };
        }
        return {
          title: obj.name,
          subtitle,
          icon,
          handleClick,
          ...obj
        };
      });
      return (
        <div className="search-results search-list">
          <List listItems={listItems} />
        </div>
      );
    } else {
      return (
        <div className="content-boxed bottom-50">
          <div className="content">
            <h3 className="bolder">No Restaurants.</h3>
          </div>
        </div>
      );
    }
  };

  const handleEditOutingName = evt => {
    setOutingName(evt.target.value);
  };

  const handleDurationSelection = evt => {
    setDuration(evt.target.value);
  };

  const handleCreateOuting = async () => {
    // const database = firebase.database();
    // const ref = database.ref('outings');
    // const options = selected.map(idx => {
    //   return props.businesses[idx];
    // });
    const uuid = uuidv1();
    const handleCreateOuting = firebase
      .functions()
      .httpsCallable('handleCreateOuting');
    handleCreateOuting({
      selected,
      businesses: props.businesses,
      uuid,
      outingName,
      duration,
      userId: props.userId,
      userName: props.userName,
      circleId: props.circle,
      outingDate,
      circles: props.circles
    }).then(data => {
      console.log('data', data);
      if (data.data.status === 'success') {
        setToastMessage('Outing Created!');
        setToastType('success');
        setShowToast(true);
        setTimeout(() => {
          props.handleActionSheetClose();
          setShowToast(false);
        }, 3000);
      } else {
        setToastMessage('An error occurred!');
        setToastType('error');
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
          props.handleActionSheetClose();
        }, 3000);
      }
    });
    // try {
    //   await ref.update({
    //     [uuid]: {
    //       name: outingName,
    //       duration,
    //       owner: props.userId,
    //       circle: props.circle,
    //       options,
    //       uuid,
    //       startDate: Date.now(),
    //       outingDate: outingDate
    //     }
    //   });
    //   const userRef = database.ref(`/users/${props.userId}/outings`);
    //   await userRef.update({
    //     [uuid]: true
    //   });
    //   const circle = props.circles && props.circles[props.circle];
    //   if (circle) {
    //     const friendsObj = circle.friends;
    //     if (friendsObj) {
    //       const friends = Object.keys(friendsObj);
    //       friends.forEach(friend => {
    //         const ref = database.ref(`users/${friend}/outings`);
    //         ref.update({
    //           [uuid]: true
    //         });
    //         const sendOutingEmail = firebase
    //           .functions()
    //           .httpsCallable('sendOutingEmail');
    //         sendOutingEmail({
    //           friendId: friend,
    //           userName: props.userName
    //         }).then(data => {
    //           console.log(data);
    //         });
    //       });
    //     }
    //   }

    //   setToastMessage('Outing Created!');
    //   setToastType('success');
    //   setShowToast(true);
    //   setTimeout(() => {
    //     props.handleActionSheetClose();
    //     setShowToast(false);
    //   }, 3000);
    // } catch (e) {
    //   setToastMessage('An error occurred!');
    //   setToastType('error');
    //   setShowToast(true);
    //   setTimeout(() => {
    //     setShowToast(false);
    //     props.handleActionSheetClose();
    //   }, 3000);
    //   console.log(e);
    // }
  };

  return (
    <div>
      <h3 className="uppercase ultrabold top-30 bottom-30">Create an Outing</h3>

      <p className="bottom-5">
        <label htmlFor="groupName">
          <i className="fa fa-1x fa-users right-10" />
          Enter a name for the Outing.
        </label>
      </p>

      <div className="search-box search-color bg-dark1-dark shadow-tiny round-huge bottom-20">
        <input id="outingName" type="text" onChange={handleEditOutingName} />
      </div>

      <p className="bottom-5">
        <label htmlFor="groupName">
          <i className="fa fa-1x fa-hourglass-half right-10" />
          Select voting duration.
        </label>
      </p>

      <div className="content top-20">
        <select className="select-css" onChange={handleDurationSelection}>
          <option value="900000">15 mins</option>
          <option value="1800000">30 mins</option>
          <option value="3600000">1 hour</option>
          <option value="14400000">4 hours</option>
          <option value="28800000">8 hours</option>
          <option value="86400000">1 day</option>
        </select>
      </div>

      <p className="bottom-5">
        <label htmlFor="groupName">
          <i className="fa fa-1x fa-calendar right-10" />
          Pick a date for the outing.
        </label>
      </p>
      <div className="date-picker-container search-box search-color bg-dark1-dark shadow-tiny round-huge bottom-20">
        <DatePicker
          selected={outingDate}
          onChange={updateDate} //only when value has changed
          minDate={new Date()}
          showTimeSelect
          timeFormat="hh:mm"
          timeIntervals={15}
          timeCaption="time"
          dateFormat="MMMM d, yyyy h:mm a"
        />
      </div>

      <p className="bottom-5">Tap The Restaurants to Include for Voting</p>
      {getResults(props)}
      <button
        className="button button-m shadow-small button-circle bg-blue2-dark top-20"
        onClick={handleCreateOuting}
        disabled={!outingName || !selected.length}
      >
        Save Outing
      </button>
      <Toast message={toastMessage} visible={showToast} type={toastType} />
    </div>
  );
}

export default CreateOuting;
