import React from 'react';
import FooterMenu from '../components/FooterMenu';
import Header from '../components/Header';
import OutingsContainer from '../components/OutingsContainer';
import Accordion from '../components/Accordion';
import List from '../components/List';
import firebase from '../firebase';

class Outings extends React.Component {
  state = {
    data: []
  };

  render() {
    return (
      <div style={{ paddingBottom: 75 }}>
        <div className="header-clear-medium">
          <Header title="Outings" subtitle="Vote" bg={38} />
        </div>

        <OutingsContainer
          outings={this.props.outings}
          userId={this.props.userId}
        />
      </div>
    );
  }
}

export default Outings;
