import React from 'react';
import FooterMenu from '../components/FooterMenu';
import Header from '../components/Header';
import Accordion from '../components/Accordion';
import Toast from '../components/Toast';
import '../components/Toggle.css';
import Toggle from 'react-toggle';
import List from '../components/List';
import firebase from '../firebase';

class Settings extends React.Component {
  state = {
    firstName: this.props.userData.firstName,
    lastName: this.props.userData.lastName,
    password: '',
    confirmPassword: '',
    isToastVisible: false,
    toastMessage: '',
    toastType: 'success',
    passwordErrorMessage: '',
    profileErrorMessage: '',
    optOutEmails: this.props.userData.optOutEmails,
    optOutEmailsErrorMessage: '',
    deactivateErrorMessage: '',
    optOutOfPublicProfile: this.props.userData.optOutOfPublicProfile,
    optOutOfPublicProfileErrorMessage: ''
  };

  handleDeactivate = evt => {
    evt.preventDefault();
    const user = firebase.auth().currentUser;

    user
      .delete()
      .then(() => {})
      .catch(error => {
        const errorCode = error.code;
        const deactivateErrorMessage = error.message;
        console.log(errorCode, deactivateErrorMessage);
        this.setState({
          deactivateErrorMessage
        });
      });
  };

  handleOptOutEmails = evt => {
    evt.preventDefault();
    const { checked } = evt.target;
    const database = firebase.database();
    const userRef = database.ref(`/users/${this.props.userId}`);
    userRef
      .update({
        optOutEmails: checked
      })
      .then(async () => {
        await this.setState({
          isToastVisible: true,
          toastMessage: 'Opt out of emails updated!',
          toastType: 'success',
          optOutEmails: checked
        });

        setTimeout(
          () =>
            this.setState({
              isToastVisible: false
            }),
          3000
        );
      })
      .catch(error => {
        const errorCode = error.code;
        const optOutEmailsErrorMessage = error.message;
        console.log(errorCode, optOutEmailsErrorMessage);
        this.setState({
          optOutEmailsErrorMessage
        });
      });
  };

  handleOptOutOfPublicProfile = evt => {
    evt.preventDefault();
    const { checked } = evt.target;
    const database = firebase.database();
    const userRef = database.ref(`/users/${this.props.userId}`);
    userRef
      .update({
        optOutOfPublicProfile: checked
      })
      .then(async () => {
        await this.setState({
          isToastVisible: true,
          toastMessage: 'Profile Updated!',
          toastType: 'success',
          optOutOfPublicProfile: checked
        });

        setTimeout(
          () =>
            this.setState({
              isToastVisible: false
            }),
          3000
        );
      })
      .catch(error => {
        const errorCode = error.code;
        const optOutOfPublicProfileErrorMessage = error.message;
        console.log(errorCode, optOutOfPublicProfileErrorMessage);
        this.setState({
          optOutOfPublicProfileErrorMessage
        });
      });
  };

  handlePasswordChange = evt => {
    evt.preventDefault();
    const user = firebase.auth().currentUser;
    const newPassword = this.state.password;

    user
      .updatePassword(newPassword)
      .then(async () => {
        await this.setState({
          isToastVisible: true,
          toastMessage: 'Password Updated.',
          toastType: 'success'
        });

        setTimeout(
          () =>
            this.setState({
              isToastVisible: false
            }),
          3000
        );
      })
      .catch(error => {
        const errorCode = error.code;
        const passwordErrorMessage = error.message;
        console.log(errorCode, passwordErrorMessage);
        this.setState({
          passwordErrorMessage
        });
      });
  };

  handleProfileDataChange = evt => {
    evt.preventDefault();
    const database = firebase.database();
    const userRef = database.ref(`/users/${this.props.userId}`);
    userRef
      .update({
        firstName: this.state.firstName,
        lastName: this.state.lastName
      })
      .then(async () => {
        await this.setState({
          isToastVisible: true,
          toastMessage: 'Profile Updated',
          toastType: 'success'
        });

        setTimeout(
          () =>
            this.setState({
              isToastVisible: false
            }),
          3000
        );
      })
      .catch(error => {
        const errorCode = error.code;
        const profileErrorMessage = error.message;
        console.log(errorCode, profileErrorMessage);
        this.setState({
          profileErrorMessage
        });
      });
  };

  handleChange = evt => {
    const { name, value } = evt.target;
    this.setState({
      [name]: value
    });
  };

  render() {
    return (
      <div style={{ paddingBottom: 75 }}>
        <div className="header-clear-medium">
          <Header title="Settings" subtitle="Your Profile and Stuff" bg={15} />
        </div>
        <div className="content-boxed">
          <div className="content bottom-25">
            <h4 className="bolder">
              Update your Profile, opt out of emails and/or public profile, and
              change your password here.
            </h4>
            <p>Tap to expand the section and see more.</p>
          </div>
          <Accordion>
            <div
              label="Profile"
              icon="fa fa-user color-blue2-dark"
              id="Profile"
            >
              <form>
                <div className="input-style input-style-1">
                  <label htmlFor="firstName" className="color-theme">
                    First Name
                  </label>
                  <input
                    id="firstName"
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={this.state.firstName}
                    onChange={this.handleChange}
                  />
                </div>

                <div className="input-style input-style-1">
                  <label htmlFor="lastName" className="color-theme">
                    Last Name
                  </label>
                  <input
                    id="lastName"
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={this.state.lastName}
                    onChange={this.handleChange}
                  />
                </div>

                <button
                  onClick={this.handleProfileDataChange}
                  className="button button-m shadow-small button-circle bg-red1-dark top-20"
                >
                  SAVE
                </button>
                <div className="firebaseui-error-wrapper">
                  <p className="firebaseui-error firebaseui-text-input-error">
                    {this.state.profileErrorMessage}
                  </p>
                </div>
              </form>
            </div>
            <div
              label="Email Settings"
              icon="fa fa-envelope color-green2-dark"
              id="Email Settings"
            >
              <Toggle
                name="optOutEmails"
                id="optOutEmails"
                value="on"
                onChange={this.handleOptOutEmails}
                defaultChecked={this.state.optOutEmails}
              />
              <label className="color-theme" htmlFor="optOutEmails">
                Opt out of Emails
              </label>
              <div className="firebaseui-error-wrapper">
                <p className="firebaseui-error firebaseui-text-input-error">
                  {this.state.optOutEmailsErrorMessage}
                </p>
              </div>
            </div>
            <div
              label="Change Your Password"
              icon="fa fa-lock color-yellow2-dark"
              id="Change Your Password"
            >
              <form>
                <div className="input-style input-style-1">
                  <label htmlFor="password" className="color-theme">
                    Password
                  </label>
                  <input
                    id="password"
                    type="password"
                    name="password"
                    placeholder="New Password"
                    value={this.state.password}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="input-style input-style-1">
                  <label htmlFor="confirmPassword" className="color-theme">
                    Confirm Password
                  </label>
                  <input
                    id="confirmPassword"
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    value={this.state.confirmPassword}
                    onChange={this.handleChange}
                  />
                </div>

                <button
                  onClick={this.handlePasswordChange}
                  className="button button-m shadow-small button-circle bg-red1-dark top-20"
                  disabled={
                    !this.state.password ||
                    this.state.password !== this.state.confirmPassword
                  }
                >
                  SAVE
                </button>
                <div className="firebaseui-error-wrapper">
                  <p className="firebaseui-error firebaseui-text-input-error">
                    {this.state.passwordErrorMessage}
                  </p>
                </div>
              </form>
            </div>
            <div
              label="Hide Profile From Public"
              icon="fab fa-snapchat-ghost color-red2-dark"
            >
              <Toggle
                name="optOutOfPublicProfile"
                id="optOutOfPublicProfile"
                value="on"
                onChange={this.handleOptOutOfPublicProfile}
                defaultChecked={this.state.optOutOfPublicProfile}
              />
              <label className="color-theme" htmlFor="optOutEmails">
                Opt out of Public Profile
              </label>
              <div className="firebaseui-error-wrapper">
                <p className="firebaseui-error firebaseui-text-input-error">
                  {this.state.optOutOfPublicProfileErrorMessage}
                </p>
              </div>
            </div>
            <div
              id="Deactivate Account"
              label="Deactivate Account"
              icon="fa fa-times-circle color-red2-dark"
            >
              <button
                onClick={this.handleDeactivate}
                className="button button-m shadow-small button-circle bg-red1-dark top-20"
              >
                DEACTIVATE ACCOUNT
              </button>
              <div className="firebaseui-error-wrapper">
                <p className="firebaseui-error firebaseui-text-input-error">
                  {this.state.deactivateErrorMessage}
                </p>
              </div>
            </div>
          </Accordion>
        </div>
        <Toast
          message={this.state.toastMessage}
          visible={this.state.isToastVisible}
          type={this.state.toastType}
        />
      </div>
    );
  }
}

export default Settings;
