import React from 'react';

import './Badge.css';

function Badge(props) {
  return (
    <span className="Badge chip chip-small bg-dark2-dark">
      <span className="color-white">{props.content}</span>
    </span>
  );
}

export default Badge;
