import React from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase';

function Header(props) {
  const handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        console.log('user signed out');
      });
  };

  const handleThemeChange = () => {
    const body = document.querySelector('body');
    body.classList.toggle('theme-dark');
  };

  return (
    <div>
      <div className="header header-fixed header-logo-center">
        <a href="https://www.groupgrubblr.com">
          <span className="header-icon header-icon-1">
            <i className="fas fa-home" />
          </span>
        </a>
        <span className="header-icon header-icon-2">
          <i className="fas fa-adjust" onClick={handleThemeChange} />
        </span>
        <a href="index.html" className="header-title">
          Group Grubblr
        </a>
        {!props.removeLogout && (
          <span className="header-icon header-icon-3">
            <i className="fas fa-sign-out-alt" onClick={handleLogout} />
          </span>
        )}
        {!props.removeSettings && (
          <Link to="/settings" className="header-icon header-icon-4">
            <i className="fas fa-cog" />
          </Link>
        )}
      </div>
      {props.bannerOnly ? (
        ''
      ) : (
        <div
          style={{ height: 100 }}
          data-height="100"
          className="caption caption-margins round-medium shadow-large"
        >
          <div className="caption-center left-15 text-left">
            <h1 className="color-white bolder">{props.title}</h1>
            <p className="under-heading color-white opacity-90 bottom-0">
              {props.subtitle}
            </p>
          </div>
          <div className="caption-overlay bg-black opacity-70" />
          <div className={`caption-bg bg-${props.bg}`} />
        </div>
      )}
    </div>
  );
}

export default Header;
