const onboardingData = [
  {
    image: '/images/onboarding/love.jpg',
    alt: 'love screen',
    heading: 'Choose The Foods You Love and Hate',
    description: `On the Love and Hate screens, tap on the cuisines that you want us to include when we search for restaurants for you or a group to which you belong. Items highlighed in green are selected. Tap on the cusine again to deselect it.`
  },
  {
    image: '/images/onboarding/onboarding-search.jpg',
    alt: 'search screen',
    heading: 'Search for Friends',
    description: `On the Search screen, search for friends via their email address. If your friend is a user, tap the result returned to send a connection request. If your friend is not a user yet, tap the button to send an invitation to your friend to join Grubblr.`
  },
  {
    image: '/images/onboarding/onboarding-groups.jpg',
    alt: 'groups screen',
    heading: 'Groups',
    description: `You can create groups and add friends to groups on the Groups screen. When you search for restaurants on the Eat screen, you can choose a group whose preferences you want taken into consideration when we find the best restaurants for you. On the Groups screen, click the Create a Group button to create a Group. Click on a group to add and remove friends from the group. Click on a friend to quickly add and remove that friend to and from groups.`
  },
  {
    image: '/images/onboarding/onboarding-eat.jpg',
    alt: 'eat screen',
    heading: 'Eat',
    description: `Search for restaurants on the Eat screen. Enter a city and state and/or a zip code or select to just use the current location of your device (you will need to give permission to the app to access your location to do so). Select the group of friends whose preferences you want included in the search. We will not include restaurants with cuisines that anyone in your group hates unless it also serves food that everyone can enjoy.`
  },
  {
    image: '/images/onboarding/onboarding-outing.jpg',
    alt: 'outing screen',
    heading: 'Create an Outing',
    description: `Create an outing so that all the members in your group can vote on any number of the restaurants that we suggest for your group. When you create the outing, give it a name and select the restaurants from the Grubblr-recommended list to be included for voting. Don't forget to set a voting duration -- or simply let it default to 15 minutes.`
  },
  {
    image: '/images/onboarding/onboarding-vote.jpg',
    alt: 'vote screen',
    heading: 'Vote on a Restaurant for an Outing',
    description: `You can vote for a restaurant for an outing that you have been invited to on the Outings screen. You can also see the current tally of votes in real-time.`
  }
];

export { onboardingData };
