import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
// This is the "Offline page" service worker

// Add this below content to your HTML page, or add the js file to your page at the very top to register service worker

// Check compatibility for the browser we're running this in
if ('serviceWorker' in navigator) {
  if (navigator.serviceWorker.controller) {
    console.log(
      '[PWA Builder] active service worker found, no need to register'
    );
  } else {
    // Register the service worker
    navigator.serviceWorker
      .register('pwabuilder-sw.js', {
        scope: './'
      })
      .then(function(reg) {
        console.log(
          '[PWA Builder] Service worker has been registered for scope: ' +
            reg.scope
        );
      });
  }
}
