import React from 'react';
import Search from './pages/Search';
import Eat from './pages/Eat';
import Love from './pages/Love';
import Hate from './pages/Hate';
import Settings from './pages/Settings';
import Outings from './pages/Outings';
import Groups from './pages/Groups';
import Login from './pages/Login';
import Register from './pages/Register';
import Onboarding from './pages/Onboarding';
import ForgotPassword from './pages/ForgotPassword';
import Spinner from './components/Spinner';
import firebaseConfig from './firebase';
import firebase from 'firebase';
import FooterMenu from './components/FooterMenu';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import './App.css';
import './Style.css';
import './Framework.css';

class App extends React.Component {
  state = {
    user: null,
    isLoading: true,
    friendsList: []
  };

  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        const database = firebase.database();
        const userRef = database.ref(`users/${user.uid}`);
        userRef.on('value', async snapshot => {
          const val = snapshot.val();
          if (val) {
            const friends = val.friends;
            let friendsList = [];
            if (friends && Object.keys(friends).length) {
              const friendsPromisesArr = Object.keys(friends).map(key => {
                const ref = database.ref(`users/${key}`);
                return ref.once('value');
              });
              const friendsdata = await Promise.all(friendsPromisesArr);

              friendsdata.forEach(snapshot => {
                if (!snapshot.val()) {
                  return;
                }
                const friendval = snapshot.val();
                const firstName = friendval.firstName;
                const lastName = friendval.lastName;
                friendsList.push({
                  firstName,
                  lastName,
                  love: friendval.love,
                  hate: friendval.hate,
                  uid: friendval.uid
                });
              });
            }

            let connectionsSentList = [];
            let connectionsReceivedList = [];
            if (val.connectionRequests) {
              const connectionsSent = val.connectionRequests.sent;
              if (connectionsSent && Object.keys(connectionsSent).length) {
                const connectionsSentKeys = Object.keys(connectionsSent);
                const invitesPromisesArr = connectionsSentKeys.map(key => {
                  const ref = database.ref(`users/${key}`);
                  return ref.once('value');
                });
                const insvitesSentdata = await Promise.all(invitesPromisesArr);

                insvitesSentdata.forEach(snapshot => {
                  if (!snapshot.val()) {
                    return;
                  }
                  const friendval = snapshot.val();
                  const firstName = friendval.firstName;
                  const lastName = friendval.lastName;
                  connectionsSentList.push({
                    firstName,
                    lastName,
                    uid: friendval.uid
                  });
                });
              }

              const connectionsReceived = val.connectionRequests.received;
              if (
                connectionsReceived &&
                Object.keys(connectionsReceived).length
              ) {
                const connectionsReceivedKeys = Object.keys(
                  connectionsReceived
                );
                const receivedPromisesArr = connectionsReceivedKeys.map(key => {
                  const ref = database.ref(`users/${key}`);
                  return ref.once('value');
                });
                const invitesReceiveddata = await Promise.all(
                  receivedPromisesArr
                );

                invitesReceiveddata.forEach(snapshot => {
                  if (!snapshot.val()) {
                    return;
                  }
                  const friendval = snapshot.val();
                  const firstName = friendval.firstName;
                  const lastName = friendval.lastName;
                  connectionsReceivedList.push({
                    firstName,
                    lastName,
                    uid: friendval.uid
                  });
                });
              }
            }

            let outings = [];
            let outingsObj = val.outings;
            if (outingsObj && Object.keys(outingsObj).length) {
              Object.keys(outingsObj).forEach((key, index) => {
                const ref = database.ref(`outings/${key}`);
                ref.on('value', snap => {
                  if (snap.val()) {
                    outings[index] = snap.val();
                    this.setState({
                      outings
                    });
                  }
                });
              });
            }

            this.setState({
              userData: snapshot.val(),
              friendsList,
              connectionsSentList,
              connectionsReceivedList,
              outings,
              userId: user.uid,
              user,
              isLoading: false
            });
          }
        });
      } else {
        this.setState({
          userData: null,
          friendsList: [],
          connectionsSentList: [],
          connectionsReceivedList: [],
          outings: [],
          userId: null,
          user: null,
          isLoading: false
        });
      }
    });
  }

  getContent = () => {
    if (this.state.user && this.state.userData.hasSeenOnboarding) {
      return (
        <div>
          <Switch>
            <Route
              path="/eat"
              exact
              render={props => (
                <Eat
                  {...props}
                  userId={this.state.userId}
                  userData={this.state.userData}
                />
              )}
            />
            <Route
              path="/groups"
              exact
              render={props => (
                <Groups
                  {...props}
                  friendsList={this.state.friendsList}
                  connectionsSentList={this.state.connectionsSentList}
                  connectionsReceivedList={this.state.connectionsReceivedList}
                  userData={this.state.userData}
                  userId={this.state.userId}
                />
              )}
            />
            <Route
              path="/love"
              exact
              render={props => (
                <Love
                  {...props}
                  userData={this.state.userData}
                  userId={this.state.userId}
                />
              )}
            />
            <Route
              path="/hate"
              exact
              render={props => (
                <Hate
                  {...props}
                  userData={this.state.userData}
                  userId={this.state.userId}
                />
              )}
            />
            <Route
              path="/settings"
              exact
              render={props => (
                <Settings
                  {...props}
                  userData={this.state.userData}
                  userId={this.state.userId}
                />
              )}
            />
            <Route
              path="/outings"
              exact
              render={props => (
                <Outings
                  {...props}
                  userData={this.state.userData}
                  userId={this.state.userId}
                  outings={this.state.outings}
                />
              )}
            />
            <Route
              path="/search"
              exact
              render={props => (
                <Search
                  {...props}
                  friendsList={this.state.friendsList}
                  userData={this.state.userData}
                  userId={this.state.userId}
                />
              )}
            />
            <Redirect to="/eat" />
          </Switch>
          <Route path="/" render={props => <FooterMenu {...props} outings={this.state.outings} />} />
        </div>
      );
    } else if (this.state.user) {
      return (
        <div>
          <Switch>
            <Route
              path="/onboarding-1"
              exact
              render={props => (
                <Onboarding {...props} screen={0} userId={this.state.userId} />
              )}
            />
            <Route
              path="/onboarding-2"
              exact
              render={props => (
                <Onboarding {...props} screen={1} userId={this.state.userId} />
              )}
            />
            <Route
              path="/onboarding-3"
              exact
              render={props => (
                <Onboarding {...props} screen={2} userId={this.state.userId} />
              )}
            />
            <Route
              path="/onboarding-4"
              exact
              render={props => (
                <Onboarding {...props} screen={3} userId={this.state.userId} />
              )}
            />
            <Route
              path="/onboarding-5"
              exact
              render={props => (
                <Onboarding {...props} screen={4} userId={this.state.userId} />
              )}
            />
            <Route
              path="/onboarding-6"
              exact
              render={props => (
                <Onboarding {...props} screen={5} userId={this.state.userId} />
              )}
            />
            <Route
              path="/onboarding-7"
              exact
              render={props => (
                <Onboarding {...props} screen={6} userId={this.state.userId} />
              )}
            />
            <Redirect to="/onboarding-1" />
          </Switch>
        </div>
      );
    } else {
      return (
        <div>
          <Switch>
            <Route path="/login" exact render={props => <Login {...props} />} />
            <Route
              path="/register"
              exact
              render={props => <Register {...props} />}
            />
            <Route
              path="/forgot-password"
              exact
              render={props => <ForgotPassword {...props} />}
            />
            <Redirect to="/login" />
          </Switch>
        </div>
      );
    }
  };

  render() {
    return (
      <Router>
        <div id="page">
          {this.state.isLoading ? (
            <div style={{ position: 'static', height: '100vh' }}>
              <Spinner />
            </div>
          ) : (
            this.getContent()
          )}
        </div>
      </Router>
    );
  }
}

export default App;
