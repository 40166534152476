import React from 'react';
import Header from '../components/Header';
import { Link } from 'react-router-dom';
import Toast from '../components/Toast';
import firebase from 'firebase';

class ForgotPassword extends React.Component {
  state = {
    email: '',
    errorMessage: '',
    showToast: false,
    toastMessage: '',
    toastType: 'success'
  };

  handleInputChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };

  sendEmail = evt => {
    evt.preventDefault();
    const { email } = this.state;
    try {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(async data => {
          this.setState(
            {
              showToast: true,
              toastMessage: 'Instructions Sent!',
              toastType: 'success'
            },
            () => {
              setTimeout(() => this.setState({ showToast: false }), 3000);
            }
          );
        })
        .catch(error => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(errorCode, errorMessage);
          this.setState({
            errorMessage
          });
        });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <div>
        <Header
          title="Get Grub"
          avatar={false}
          bannerOnly={true}
          removeSettings={true}
          removeLogout={true}
        />
        <div className="content-boxed left-50 right-50 top-60">
          <div className="content top-20 bottom-40">
            <h1 className="center-text">
              <i className="fa fa-3x fa-question-circle color-highlight bottom-30" />
            </h1>
            <h1 className="center-text uppercase ultrabold fa-3x">Forgot</h1>
            <p className="center-text font-11 under-heading bottom-20">
              Let's get you back into your account.
            </p>
            <div className="input-style has-icon input-style-1 input-required">
              <i className="input-icon fa fa-at" />
              <span>Email</span>
              <em>(required)</em>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                onChange={this.handleInputChange}
              />
            </div>
            <button
              className="button button-full button-m shadow-large button-round-small bg-highlight top-30 bottom-0"
              onClick={this.sendEmail}
            >
              SEND RESET INSTRUCTIONS
            </button>
            <div className="firebaseui-error-wrapper">
              <p className="firebaseui-error firebaseui-text-input-error">
                {this.state.errorMessage}
              </p>
            </div>
            <div className="divider bottom-20" />
            <div className="bottom-20">
              <Link
                to="/login"
                className="back-button center-text font-11 color-theme"
              >
                <span>Sign In Here.</span>
              </Link>
            </div>
          </div>
        </div>
        <Toast
          message={this.state.toastMessage}
          visible={this.state.showToast}
          type={this.state.toastType}
        />
      </div>
    );
  }
}

export default ForgotPassword;
