import React from 'react';
import { Link } from 'react-router-dom';

function FooterMenu(props) {
  let currentOutingsCount = getPendingOutings(props);
  let outingsNumTitle = "Outings";
  if (currentOutingsCount > 0) {
    outingsNumTitle += " (" + currentOutingsCount + ")";
  }
  const location = props.location.pathname;
  const locations = [
    {
      path: '/eat',
      icon: 'fas fa-utensils',
      title: 'Eat'
    },
    {
      path: '/search',
      icon: 'fa fa-search',
      title: 'Find Friends'
    },
    {
      path: '/groups',
      icon: 'fa fa-users',
      title: 'Groups'
    },
    {
      path: '/love',
      icon: 'fa fa-grin',
      title: 'Love'
    },
    {
      path: '/hate',
      icon: 'fa fa-frown-open',
      title: 'Hate'
    },
    {
      path: '/outings',
      icon: 'fa fa-calendar-check',
      title: outingsNumTitle
    }
  ];
  return (
    <div id="footer-menu" className="footer-menu-6-icons footer-menu-style-1">
      {locations.map(loc => {
        const className = loc.path === location ? 'active-nav' : '';
        return (
          <Link key={loc.path} to={loc.path} className={className}>
            <i className={loc.icon} />
            <span>{loc.title}</span>
          </Link>
        );
      })}
      <div className="clear" />
    </div>
  );
}

function getPendingOutings(props) {
  let currentOutingsCount = 0;
  props.outings.forEach(outing => {
    const currentDate = Date.now();
    const startDate = parseInt(outing.startDate);
    const duration = parseInt(outing.duration);
    const endDate = startDate + duration;
    
    if (currentDate < endDate) {
      currentOutingsCount++;
    } 
  });
  return currentOutingsCount;
}

export default FooterMenu;
