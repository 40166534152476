import React, { Component } from 'react';

class AccordionSection extends Component {
  onClick = () => {
    this.props.onClick(this.props.id);
  };

  render() {
    const {
      onClick,
      props: { isOpen, label, icon }
    } = this;

    return (
      <div
        style={{
          border: '1px solid rgba(255,255,255,0.07)',
          padding: '5px 10px'
        }}
      >
        <div
          onClick={onClick}
          style={{ cursor: 'pointer', height: 40, lineHeight: '40px' }}
          className="color-theme"
        >
          {icon && <i className={`${icon} right-10`} />}
          {label}
          <div style={{ float: 'right' }}>
            {!isOpen && (
              <i className="fa dropdown-icon fa-angle-down color-theme" />
            )}
            {isOpen && (
              <i className="fa dropdown-icon fa-angle-up color-theme" />
            )}
          </div>
        </div>
        {isOpen && (
          <div
            style={{
              padding: '10px 20px'
            }}
          >
            {this.props.children}
          </div>
        )}
      </div>
    );
  }
}

export default AccordionSection;
