import React from 'react';

class ActionSheet extends React.Component {
  state = {
    isOpen: this.props.isOpen
  };

  handleClose = () => {
    this.setState({
      isOpen: false
    });
    if (this.props.handleClose) {
      this.props.handleClose();
    }
  };

  componentWillReceiveProps(props) {
    if (props.isOpen != null) {
      this.setState({
        isOpen: props.isOpen
      });
    }
  }

  render() {
    const style = {
      height: this.state.isOpen ? '80vh' : 0,
      opacity: this.state.isOpen ? 1 : 0,
      display: 'block',
      transition: 'all 0.3s ease-out'
    };
    let className = 'menu menu-box-bottom menu-box-detached round-medium';
    className = this.state.isOpen ? `${className} menu-active` : className;
    return (
      <div
        className={className}
        data-menu-height="320"
        data-menu-effect="menu-over"
        style={style}
      >
        <div className="boxed-text-huge-alt">
          {this.props.content}
          <button
            className="button button-xs button-round-medium shadow-large bg-highlight"
            onClick={this.handleClose}
            style={{ textAlign: 'center' }}
          >
            Close
          </button>
        </div>
      </div>
    );
  }
}

export default ActionSheet;
