import React from 'react';

function ListItem(props) {
  const { icon, title, subtitle, handleClick } = props;
  return handleClick ? (
    <li onClick={handleClick}>
      <i className={`${icon}`} />
      <span className="color-theme">{title}</span>
      <strong>{subtitle}</strong>
      <i className="fa fa-angle-right" />
    </li>
  ) : (
    <li>
      <i className={`${icon}`} />
      <span>{title}</span>
      <strong>{subtitle}</strong>
    </li>
  );
}

function List(props) {
  return (
    <ul className="link-list link-list-2 link-list-long-border">
      {props.listItems.map((item, index) => {
        if (item.handleClick) {
          return (
            <ListItem
              key={index}
              icon={item.icon}
              title={item.title}
              subtitle={item.subtitle}
              handleClick={item.handleClick}
            />
          );
        } else {
          return (
            <ListItem
              key={index}
              icon={item.icon}
              title={item.title}
              subtitle={item.subtitle}
            />
          );
        }
      })}
    </ul>
  );
}

export default List;
