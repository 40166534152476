import React, { Component } from 'react';
import AccordionSection from './AccordionSection';

class Accordion extends Component {
  constructor(props) {
    super(props);

    const openSections = {};

    this.state = { openSections };
  }

  onClick = id => {
    const {
      state: { openSections }
    } = this;

    const isOpen = !!openSections[id];

    this.setState({
      openSections: {
        [id]: !isOpen
      }
    });
  };

  render() {
    let {
      onClick,
      props: { children },
      state: { openSections }
    } = this;
    if (!Array.isArray(children)) {
      children = [children];
    }

    return (
      <div>
        {children.map((child, index) => (
          <AccordionSection
            key={index}
            id={child.props.id}
            isOpen={!!openSections[child.props.id]}
            label={child.props.label}
            icon={child.props.icon}
            onClick={onClick}
          >
            {child.props.children}
          </AccordionSection>
        ))}
      </div>
    );
  }
}

export default Accordion;
