import React from 'react';
import FooterMenu from '../components/FooterMenu';
import Header from '../components/Header';
import ActionSheet from '../components/ActionSheet';
import List from '../components/List';
import Tabs from '../components/Tabs';
import Invitations from '../components/Invitations';
import ConnectionRequests from '../components/ConnectionRequests';
import ConnectionRequestsReceived from '../components/ConnectionRequestsReceived';
import Accordion from '../components/Accordion';
import firebase from '../firebase';

class Groups extends React.Component {
  state = {
    isActionSheetOpen: false,
    sheetContent: '',
    friendsList: [],
    data: [],
    groupName: ''
  };

  componentDidMount() {
    console.log(this.props);
  }

  removeUserFromGroup = (user, group) => {
    const database = firebase.database();
    const userRef = database.ref(
      `users/${this.props.userId}/circles/${group}/friends/${user}`
    );
    userRef.remove();
  };

  addUserToGroup = (user, group) => {
    const database = firebase.database();
    const userRef = database.ref(
      `users/${this.props.userId}/circles/${group}/friends/${user}`
    );
    userRef.set(true);
  };

  getSheetContent = () => {
    let listItems = [];
    switch (this.state.sheetContent) {
      case 'addGroup':
        return (
          <div>
            <h3 className="uppercase ultrabold top-30 bottom-30">
              Create a New Group
            </h3>

            <p className="bottom-5">
              <label htmlFor="groupName">
                <i className="fa fa-1x fa-users right-10" />
                Enter a name for the group.
              </label>
            </p>

            <div className="search-box search-color bg-dark1-dark shadow-tiny round-huge bottom-20">
              <input
                id="groupName"
                type="text"
                onChange={this.handleEditGroupName}
              />
            </div>
            <button
              style={{ display: 'block' }}
              className="button button-m shadow-small button-circle bg-red1-dark top-20"
              onClick={this.handleCreateNewGroup}
            >
              Save
            </button>
          </div>
        );
      case 'editGroup':
        listItems = this.props.friendsList.map(friend => {
          let subtitle = 'Tap to Add User';
          let icon = 'fas fa-times color-red1-dark';
          let handleClick = () => {
            this.addUserToGroup(friend.uid, this.state.groupToEdit);
          };
          if (
            this.props.userData.circles[this.state.groupToEdit].friends &&
            this.props.userData.circles[this.state.groupToEdit].friends[
              friend.uid
            ]
          ) {
            subtitle = 'Tap to Remove User';
            icon = 'fas fa-check color-green1-light';
            handleClick = () => {
              this.removeUserFromGroup(friend.uid, this.state.groupToEdit);
            };
          }
          return {
            title: `${friend.firstName} ${friend.lastName}`,
            subtitle,
            icon,
            handleClick
          };
        });
        return (
          <div>
            <h3 className="uppercase ultrabold top-30 bottom-30">Edit Group</h3>
            <List listItems={listItems} />
          </div>
        );
      case 'editUser':
        if (this.props.userData.circles) {
          listItems = Object.keys(this.props.userData.circles).map(key => {
            let subtitle = 'Tap to Add User';
            let icon = 'fas fa-times color-red1-dark';
            let handleClick = () => {
              this.addUserToGroup(this.state.userToEdit, key);
            };
            if (
              this.props.userData.circles[key].friends &&
              this.props.userData.circles[key].friends[this.state.userToEdit]
            ) {
              subtitle = 'Tap to Remove User';
              icon = 'fas fa-check color-green1-light';
              handleClick = () => {
                this.removeUserFromGroup(this.state.userToEdit, key);
              };
            }
            return {
              title: this.props.userData.circles[key].name,
              subtitle,
              icon,
              handleClick
            };
          });
          return (
            <div>
              <h3 className="uppercase ultrabold top-30 bottom-30">
                Edit User
              </h3>
              <List listItems={listItems} />
            </div>
          );
        } else {
          return (
            <div>
              <h3 className="uppercase ultrabold top-30 bottom-30">
                Edit User
              </h3>
              <p>You have no groups. Try adding a group first.</p>
            </div>
          );
        }
      default:
        return <h1>Default</h1>;
    }
  };

  handleCreateGroup = () => {
    this.setState({
      isActionSheetOpen: true,
      sheetContent: 'addGroup'
    });
  };

  handleCreateNewGroup = () => {
    const database = firebase.database();
    const circlesRef = database.ref(`users/${this.props.userId}/circles/`);
    circlesRef.push().set({
      name: this.state.groupName
    });
    this.setState({
      isActionSheetOpen: false,
      groupName: ''
    });
  };

  handleEditGroupName = evt => {
    this.setState({
      groupName: evt.target.value
    });
  };

  handleEditUser = uid => {
    this.setState({
      isActionSheetOpen: true,
      sheetContent: 'editUser',
      userToEdit: uid
    });
  };

  handleEditGroup = groupId => {
    this.setState({
      isActionSheetOpen: true,
      sheetContent: 'editGroup',
      groupToEdit: groupId
    });
  };

  getFriendResults = () => {
    if (this.props.friendsList.length) {
      const listItems = this.props.friendsList.map(friend => {
        return {
          title: `${friend.firstName} ${friend.lastName}`,
          subtitle: 'Tap to add/remove user to groups.',
          icon: 'fas fa-user-circle',
          handleClick: () => {
            this.handleEditUser(friend.uid);
          }
        };
      });
      return (
        <div className="search-results search-list">
          <List listItems={listItems} />
        </div>
      );
    } else {
      return (
        <div className="content-boxed bottom-50">
          <div className="content">
            <h3 className="bolder">
              No Friends Yet. Go to search to find and invite friends.
            </h3>
          </div>
        </div>
      );
    }
  };

  getGroupResults = listItems => {
    if (
      this.props.userData.circles &&
      Object.keys(this.props.userData.circles).length
    ) {
      const listItems = Object.keys(this.props.userData.circles).map(circle => {
        return {
          title: this.props.userData.circles[circle].name,
          subtitle: 'Tap to edit group.',
          icon: 'fas fa-users-cog',
          handleClick: () => {
            this.handleEditGroup(circle);
          }
        };
      });
      return (
        <div className="search-results search-list">
          <List listItems={listItems} />
        </div>
      );
    } else {
      return (
        <div className="content-boxed bottom-50">
          <div className="content">
            <h3 className="bolder">No Groups Yet. Try Creating one.</h3>
          </div>
        </div>
      );
    }
  };

  render() {
    return (
      <div>
        <div className="page-content header-clear-medium">
          <Header title="Groups" subtitle="Manage Your Groups" bg={37} />
          <button
            style={{ margin: '0 auto', display: 'block' }}
            className="button button-m shadow-small button-circle bg-red1-dark top-20 bottom-20"
            onClick={this.handleCreateGroup}
          >
            Create a New Group
          </button>
          <Tabs>
            <div label="Friends">
              <div className="content-boxed">
                <p className="content bottom-25">
                  <i className="fa fa-1x fa-user-edit right-10" />
                  Tap a friend to Add/Remove from groups.
                </p>
              </div>
              {this.getFriendResults()}
            </div>
            <div label="Groups">
              <div className="content-boxed">
                <p className="content bottom-25">
                  <i className="fa fa-1x fa-edit right-10" />
                  Tap a Group to Edit and Add/Remove friends.
                </p>
              </div>
              {this.getGroupResults(this.state.data)}
            </div>
            <div label="Invites and Requests">
              <div className="content-boxed">
                <div className="content bottom-25">
                  <h4 className="bolder">
                    Invitations and Connection Requests
                  </h4>
                  <p>Tap to expand the section and see more.</p>
                </div>
                <Accordion>
                  <div
                    id="Pending Invitations"
                    label="Pending Invitations"
                    icon="fa fa-envelope color-blue2-dark"
                  >
                    <Invitations
                      userId={this.props.userId}
                      invitations={this.props.userData.invitations}
                    />
                  </div>
                  <div
                    id="Requested Connection Requests"
                    label="Requested Connection Requests"
                    icon="fa fa-user-check color-red2-dark"
                  >
                    <ConnectionRequests
                      userId={this.props.userId}
                      requests={this.props.connectionsSentList}
                    />
                  </div>
                  <div
                    id="Received Connection Requests"
                    label="Received Connection Requests"
                    icon="fa fa-user-plus color-yellow2-dark"
                  >
                    <ConnectionRequestsReceived
                      userId={this.props.userId}
                      requests={this.props.connectionsReceivedList}
                    />
                  </div>
                </Accordion>
              </div>
            </div>
          </Tabs>
        </div>
        <ActionSheet
          isOpen={this.state.isActionSheetOpen}
          content={this.getSheetContent()}
        />
      </div>
    );
  }
}

export default Groups;
