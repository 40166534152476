import React, { useState } from 'react';
import List from './List';
import Toast from './Toast';
import firebase from '../firebase';

function Invitations(props) {
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState('');

  const handleCancelInvitation = (id, email) => {
    const database = firebase.database();

    database
      .ref(`users/${id}/invitations`)
      .orderByChild('email')
      .equalTo(email)
      .once('value')
      .then(snapshot => {
        if (snapshot.val()) {
          Object.keys(snapshot.val()).forEach(key => {
            firebase
              .database()
              .ref(`users/${id}/invitations/${key}`)
              .remove();
          });
          setShowToast(true);
          setToastMessage('Invite Removed!');
          setToastType('success');
          setTimeout(() => setShowToast(false), 3000);
        }
      })
      .catch(error => {
        setShowToast(true);
        setToastMessage('An Error Occurred');
        setToastType('error');
        setTimeout(() => setShowToast(false), 3000);
        console.log('Remove invitation failed because ', error);
      });

    database
      .ref(`invitations`)
      .orderByChild('requestor')
      .equalTo(id)
      .once('value')
      .then(snapshot => {
        if (snapshot.val()) {
          Object.keys(snapshot.val()).forEach(key => {
            if (snapshot.val()[key].requestee === email) {
              database.ref(`invitations/${key}`).remove();
            }
          });
        }
      });
  };

  const getConnectionRequestResults = props => {
    if (props.invitations && Object.keys(props.invitations).length) {
      const listItems = Object.values(props.invitations).map(obj => {
        return {
          title: `${obj.email}`,
          subtitle: 'Tap to cancel invitation.',
          icon: 'fas fa-user-circle',
          handleClick: () => {
            handleCancelInvitation(props.userId, obj.email);
          }
        };
      });
      return (
        <div className="search-results search-list">
          <List listItems={listItems} />
        </div>
      );
    } else {
      return (
        <div className="content-boxed bottom-50">
          <div className="content">
            <h3 className="bolder">
              No Pending Invitations. Go to search to find and invite friends.
            </h3>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      {getConnectionRequestResults(props)}
      <Toast message={toastMessage} visible={showToast} type={toastType} />
    </div>
  );
}

export default Invitations;
