import React from 'react';
import FooterMenu from '../components/FooterMenu';
import Header from '../components/Header';
import LazyLoad from 'react-lazyload';
import firebase from '../firebase';

class Love extends React.Component {
  state = {
    data: []
  };

  componentDidMount() {
    const database = firebase.database();
    const foodsRef = database.ref(`/foods`);
    foodsRef.on('value', snapshot => {
      this.setState({
        data: snapshot.val(),
        isLoading: false
      });
    });
  }

  handleClick = food => {
    const database = firebase.database();
    let loveRef;
    const love = this.props.userData.love || {};
    const loveArr = Object.keys(love);
    if (loveArr.indexOf(food) !== -1) {
      loveRef = database.ref(`/users/${this.props.userId}/love/${food}`);
      loveRef.remove();
    } else {
      loveRef = database.ref(`/users/${this.props.userId}/love/`);
      loveRef.update({
        [food]: true
      });
    }
  };

  getFoodsResults = () => {
    if (!this.state.data || !Object.keys(this.state.data).length) {
      return '';
    }

    return Object.keys(this.state.data).map(key => {
      const image = this.state.data[key].image;
      const name = this.state.data[key].name;
      const love = this.props.userData.love || {};
      const loveArr = Object.keys(love);
      let className = 'color-theme';
      if (loveArr.indexOf(key) !== -1) {
        className = `${className} bg-green1-dark`;
      }
      return (
        <li key={key} onClick={() => this.handleClick(key)}>
          <LazyLoad>
            <img
              src={`/images/foods/${image}`}
              data-src={`/images/foods/${image}`}
              className="preload-image responsive-image"
              alt={name}
            />
          </LazyLoad>
          <span className={className}>{name}</span>
        </li>
      );
    });
  };

  render() {
    return (
      <div>
        <div className="page-content header-clear-medium">
          <Header title="Love" subtitle="Foods You Love" bg={34} />

          <div className="bottom-50">
            <div className="content-boxed love-hate-container">
              <div className="content bottom-0">
                <h3 className="bolder">Tap the cuisines you love.</h3>
                <p>
                  We'll be sure to include your selections when you search for
                  grub. Those highlighted with green are selected.
                </p>
                <div className="gallery gallery-thumbs gallery-square bottom-0">
                  <ul>{this.getFoodsResults()}</ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Love;
