import React from 'react';

const styles = {
  container: {},
  media: {
    width: 100,
    height: 100,
    float: 'left'
  },
  content: {
    marginLeft: 120
  }
};

function Card(props) {
  const {
    name,
    price,
    review,
    address,
    phoneNumber,
    image,
    latitude,
    longitude,
    categories
  } = props;
  return (
    <div>
      <h3 className="uppercase ultrabold top-30">{name}</h3>
      <div style={styles.container} className="bottom-20">
        <img style={styles.media} src={image} alt="" />
        <div style={styles.content}>
          <div className="link-list link-list-1">
            <a href={`tel:${phoneNumber}`} data-menu="menu-tour-1">
              <i className="fa fa-phone color-yellow1-dark" />
              <span>{phoneNumber}</span>
              <i className="fa fa-angle-right" />
            </a>
          </div>
          <div className="link-list link-list-1">
            <a
              href={review}
              target="_blank"
              rel="noopener noreferrer"
              data-menu="menu-tour-1"
            >
              <i className="fa fa-money-check-alt color-green1-dark" />
              <span>{price}</span>
            </a>
          </div>
          <div className="link-list link-list-1">
            <a
              href={review}
              target="_blank"
              rel="noopener noreferrer"
              data-menu="menu-tour-1"
            >
              <i className="fa fa-star color-yellow1-dark" />
              <span>Yelp Review</span>
              <i className="fa fa-angle-right" />
            </a>
          </div>
        </div>
        <div className="link-list link-list-1">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`}
            data-menu="menu-tour-1"
          >
            <i className="fa fa-map-marker color-red1-dark" />
            <span>{address}</span>
            <i className="fa fa-angle-right" />
          </a>
          <div className="link-list link-list-1">
            <ul>
              {categories.map(category => {
                return (
                  <li
                    key={category.alias}
                    style={{ display: 'inline-block', marginRight: 10 }}
                  >
                    {category.title}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
