import React, { useState } from 'react';
import Accordion from './Accordion';
import Badge from './Badge';
import List from './List';
import Toast from './Toast';
import firebase from '../firebase';
import moment from 'moment';

function Outing(props) {
  const totalsMap = new Map();
  if (props.outing && props.outing.votes) {
    Object.values(props.outing.votes).forEach(option => {
      if (totalsMap.has(option)) {
        totalsMap.set(option, totalsMap.get(option) + 1);
      } else {
        totalsMap.set(option, 1);
      }
    });
  }

  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState('');
  const [choice, setChoice] = useState(
    props.outing.votes ? props.outing.votes[props.userId] : ''
  );

  const handleVote = async (outingId, optionId, evt) => {
    // const database = firebase.database();
    // const ref = database.ref(`outings/${outingId}`);
    // const snapshot = await ref.once('value');
    // if (!snapshot.val() || !snapshot.val().options) {
    //   return;
    // }
    setChoice(optionId);
    const database = firebase.database();
    const votingRef = database.ref(`outings/${outingId}/votes`);
    await votingRef.update({
      [props.userId]: optionId
    });
  };

  const getOptionsResults = outing => {
    if (!outing.options) {
      return '';
    }

    if (props.isOver) {
      return outing.options.map(option => {
        return (
          <div key={option.id}>
            <span className="color-theme right-10">{option.name}</span>
            <Badge content={totalsMap.get(option.id) || 0} />
          </div>
        );
      });
    } else {
      return outing.options.map(option => {
        return (
          <div className="" key={option.id}>
            <span />
            <input
              id={option.id}
              type="radio"
              name={outing.uuid}
              value={option.id}
              checked={option.id === choice}
              onChange={evt => {
                handleVote(outing.uuid, option.id, evt);
              }}
            />
            <label
              className="color-theme left-10 right-10"
              style={{
                display: 'inline-block',
                verticalAlign: 'text-bottom'
              }}
              htmlFor={option.id}
            >
              {option.name}
            </label>
            <Badge content={totalsMap.get(option.id) || 0} />
          </div>
        );
      });
    }
  };

  return (
    <div>
      <div>
        <p>Outing timing: {moment(props.outing.outingDate).format('LLLL')} </p>
      </div>
      {getOptionsResults(props.outing)}
      {!props.isOver ? (
        <div>
          <p>
            Voting started at {moment(props.outing.startDate).format('LLLL')}{' '}
            and will end at{' '}
            {moment(
              props.outing.startDate + parseInt(props.outing.duration)
            ).format('LLLL')}{' '}
            .
          </p>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default Outing;
