import firebase from 'firebase';
import * as firebaseui from 'firebaseui';
const config = {
  apiKey: 'AIzaSyAke3HgB_ZoQWqoLe0PPdeqy0C98IJozOY',
  authDomain: 'grubblr3.firebaseapp.com',
  databaseURL: 'https://grubblr3.firebaseio.com',
  projectId: 'grubblr3',
  storageBucket: '',
  messagingSenderId: '891200197372',
  appId: '1:891200197372:web:bafd34783126a1b2'
};
const uiConfig = {
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ],
  credentialHelper: firebaseui.auth.CredentialHelper.NONE,
  // tosUrl and privacyPolicyUrl accept either url string or a callback
  // function.
  // Terms of service url/callback.
  signInSuccessUrl: 'eat',
  tosUrl: 'eat',
  // Privacy policy url/callback.
  privacyPolicyUrl: function() {
    window.location.assign('eat');
  }
};
firebase.uiConfig = uiConfig;
firebase.initializeApp(config);
export default firebase;
