import React, { useState } from 'react';
import Accordion from './Accordion';
import { Link } from 'react-router-dom';
import Outing from './Outing';
import uuidv1 from 'uuid/v1';

function OutingsContainer(props) {
  const [toastMessage, setToastMessage] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState('');

  const currentOutings = [];
  const prevOutings = [];
  props.outings.forEach(outing => {
    const currentDate = Date.now();
    const startDate = parseInt(outing.startDate);
    const duration = parseInt(outing.duration);
    const endDate = startDate + duration;

    if (currentDate < endDate) {
      currentOutings.push(outing);
    } else {
      prevOutings.push(outing);
    }
  });

  return (
    <div>
      <div className="content-boxed bottom-20">
        <div className="content bottom-20">
          <h4>Current Outings</h4>
          <p>Tap to expand the section and participate in your outing.</p>
        </div>
        {currentOutings.length ? (
          <Accordion className="content bottom-50">
            {currentOutings.map((obj, index) => {
              const id = uuidv1();
              return (
                <div
                  id={id}
                  label={obj.name}
                  key={obj.uuid}
                  icon="fa fa-calendar-check color-green2-dark"
                >
                  <Outing outing={obj} userId={props.userId} isOver={false} />;
                </div>
              );
            })}
          </Accordion>
        ) : (
          <p className="color-theme left-15">
            There are no current outings. Go to <Link to="/eat">Eat</Link> and
            search for restaurants to create one.
          </p>
        )}
      </div>
      <div className="content-boxed bottom-20">
        <div className="content bottom-20">
          <h4>Previous Outings</h4>
          <p>Tap to expand the section and see the outcome.</p>
        </div>

        <Accordion className="content bottom-50">
          {prevOutings.map((obj, index) => {
            const id = uuidv1();
            return (
              <div
                id={id}
                label={obj.name}
                key={obj.uuid}
                icon="fa fa-calendar-check color-green2-dark"
              >
                <Outing outing={obj} userId={props.userId} isOver={true} />;
              </div>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
}

export default OutingsContainer;
