import React from 'react';
import Header from '../components/Header';
import { onboardingData } from '../onboarding-data';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import firebase from '../firebase';

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  heading1: {
    marginTop: 100
  },
  imageContainer: {
    width: '80%',
    maxWidth: 400
  },
  image: {
    width: '100%',
    height: 'auto',
    margin: '0 auto'
  },
  info: {
    width: '80%'
  },
  pagination: {
    width: '80%'
  }
};

function Onboarding(props) {
  const handleGetStarted = () => {
    const database = firebase.database();
    const userRef = database.ref(`users/${props.userId}/`);
    userRef.update({
      hasSeenOnboarding: true
    });
  };

  const data = onboardingData[props.screen];

  return (
    <div style={styles.container}>
      <Header
        title="Get Grub"
        avatar={false}
        bannerOnly={true}
        removeSettings={true}
      />
      <h1 style={styles.heading1}>Grubblr Onboarding</h1>
      <p className="numberCircle">{props.screen + 1}</p>
      <div className="bottom-20" style={styles.imageContainer}>
        <LazyLoad>
          <img style={styles.image} alt={data.alt} src={data.image} />
        </LazyLoad>
      </div>
      <h2>{data.heading}</h2>
      <p style={styles.info}>{data.description}</p>
      <div style={styles.pagination} className="pagination">
        {props.screen > 0 && (
          <Link
            to={`/onboarding-${props.screen}`}
            className="bg-black color-white"
          >
            <i className="fa fa-angle-left" />
          </Link>
        )}

        {onboardingData.map((obj, index) => {
          let paginationClassName = 'bg-black color-white';
          if (props.screen === index) {
            paginationClassName = 'bg-dark1-light color-white';
          }
          return (
            <Link
              to={`/onboarding-${index + 1}`}
              className={paginationClassName}
              key={index}
            >
              {index + 1}
            </Link>
          );
        })}

        {props.screen < onboardingData.length - 1 && (
          <Link
            to={`/onboarding-${props.screen + 2}`}
            className="bg-black color-white"
          >
            <i className="fa fa-angle-right" />
          </Link>
        )}
      </div>
      <button
        style={{ margin: '0 auto', display: 'block' }}
        className="button button-m shadow-small button-circle bg-red1-dark top-20 bottom-20"
        onClick={handleGetStarted}
      >
        Get Started!
      </button>
    </div>
  );
}

export default Onboarding;
