import React from 'react';
import FooterMenu from '../components/FooterMenu';
import Header from '../components/Header';
import List from '../components/List';
import Toast from '../components/Toast';
import ActionSheet from '../components/ActionSheet';
import Card from '../components/Card';
import CreateOuting from '../components/CreateOuting';
import Toggle from 'react-toggle';
import firebase from '../firebase';

class Eat extends React.Component {
  state = {
    location: '',
    hasSearched: false,
    isLoading: false,
    data: [],
    businesses: [],
    originalBusinesses: [],
    sheetContent: 0,
    isActionSheetOpen: false,
    useCurrentLocation: false,
    latitude: '',
    longitude: '',
    selectedGroup: 'solo'
  };

  getSheetContent = () => {
    if (!this.state.data.length && this.state.sheetContent !== 'createOuting') {
      return '';
    }
    if (this.state.sheetContent === 'createOuting') {
      return (
        <CreateOuting
          userId={this.props.userId}
          userName={`${this.props.userData.firstName} ${this.props.userData.lastName}`}
          businesses={this.state.businesses}
          circle={this.state.selectedGroup}
          circles={this.props.userData.circles}
          handleActionSheetClose={this.handleActionSheetClose}
        />
      );
    } else {
      const content = this.state.data[this.state.sheetContent];
      return (
        <Card
          name={content.name}
          price={content.price || '$'}
          review={content.url}
          address={`${content.location.display_address[0]}
          ${content.location.display_address[1]}`}
          phoneNumber={content.display_phone}
          image={content.image_url}
          latitude={content.coordinates.latitude}
          longitude={content.coordinates.longitude}
          categories={content.categories}
        />
      );
    }
  };

  handleUseCurrentLocation = () => {
    this.setState({
      useCurrentLocation: !this.state.useCurrentLocation
    });
  };

  componentDidMount() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        console.log(position);
        this.setState({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        });
      });
    } else {
      /* geolocation IS NOT available */
    }
  }

  handleOnChange = evt => {
    this.setState({
      location: evt.target.value
    });
  };

  handleActionSheetClose = () => {
    this.setState({
      isActionSheetOpen: false
    });
  };

  showDetails = idx => {
    this.setState({
      isActionSheetOpen: true,
      sheetContent: idx
    });
  };

  handleSearch = async () => {
    console.log(this.props.userData);
    await this.setState({
      isLoading: true
    });
    const { love, hate, circles } = this.props.userData;
    let categories = '';
    if (this.state.selectedGroup === 'solo') {
      if (love) {
        categories = Object.keys(love).join();
      } else {
        categories = '';
      }
    } else {
      const selectedGroup = circles[this.state.selectedGroup];
      if (!selectedGroup.friends) {
        if (love) {
          categories = Object.keys(love).join();
        } else {
          categories = '';
        }
      } else {
        const database = firebase.database();
        const friends = selectedGroup.friends;
        const friendsPromisesArr = Object.keys(friends).map(key => {
          const ref = database.ref(`users/${key}`);
          return ref.once('value');
        });
        const hateSet = new Set();
        if (hate) {
          Object.keys(this.props.userData.hate).forEach(key => {
            hateSet.add(key);
          });
        }
        const loveSet = new Set();
        if (love) {
          Object.keys(love).forEach(key => {
            loveSet.add(key);
          });
        }

        const friendsdata = await Promise.all(friendsPromisesArr);
        friendsdata.forEach(snapshot => {
          if (!snapshot.val()) {
            return;
          }
          const val = snapshot.val();
          if (val.hate) {
            Object.keys(val.hate).forEach(key => {
              hateSet.add(key);
            });
          }
          if (val.love) {
            Object.keys(val.love).forEach(key => {
              loveSet.add(key);
            });
          }
        });
        const categoriesArr = [...loveSet];

        hateSet.forEach(food => {
          if (categoriesArr.indexOf(food) > -1) {
            categoriesArr.splice(categoriesArr.indexOf(food), 1);
          }
        });
        console.log(categoriesArr);
        categories = categoriesArr.join();
      }
    }
    console.log('categories', categories);

    let url = `https://us-central1-grubblr3.cloudfunctions.net/search?term=restaurants&categories=${categories}`;

    if (this.state.useCurrentLocation) {
      const longitude = this.state.longitude;
      const latitude = this.state.latitude;
      url = `${url}&latitude=${latitude}&longitude=${longitude}`;
    } else {
      const location = this.state.location;
      url = `${url}&location=${location}`;
    }

    const response = await fetch(url);
    const json = await response.json();
    const businesses = json.businesses;
    console.log(businesses);
    const data = businesses.map((obj, index) => {
      return {
        title: obj.name,
        subtitle: 'Tap for Details',
        icon: 'fas fa-utensils',
        handleClick: () => {
          this.showDetails(index);
        },
        ...obj
      };
    });

    this.setState({
      data,
      businesses,
      originalBusinesses: businesses,
      isLoading: false
    });
  };

  getResults = listItems => {
    if (this.state.data.length) {
      return (
        <div className="search-results search-list">
          <List listItems={listItems} />
        </div>
      );
    } else if (this.state.hasSearched) {
      return (
        <div className="content-boxed bottom-50">
          <div className="content">
            <h3 className="bolder">No Results</h3>
          </div>
        </div>
      );
    } else {
      return '';
    }
  };

  handleGroupSelection = evt => {
    this.setState({
      selectedGroup: evt.target.value
    });
  };

  handleCreateOuting = () => {
    this.setState({
      isActionSheetOpen: true,
      sheetContent: 'createOuting'
    });
  };

  handleFilterGlutenFree = evt => {
    evt.preventDefault();
    const { checked } = evt.target;
    if (checked) {
      const businesses = [];
      this.state.originalBusinesses.forEach(business => {
        const categories = business.categories;
        const isGF = categories.some(category => {
          return category.alias === 'gluten_free';
        });
        if (isGF) {
          businesses.push(business);
        }
      });
      const data = businesses.map((obj, index) => {
        return {
          title: obj.name,
          subtitle: 'Tap for Details',
          icon: 'fas fa-utensils',
          handleClick: () => {
            this.showDetails(index);
          },
          ...obj
        };
      });
      this.setState({
        businesses,
        data
      });
    } else {
      const data = this.state.originalBusinesses.map((obj, index) => {
        return {
          title: obj.name,
          subtitle: 'Tap for Details',
          icon: 'fas fa-utensils',
          handleClick: () => {
            this.showDetails(index);
          },
          ...obj
        };
      });
      this.setState({
        businesses: this.state.originalBusinesses,
        data
      });
    }
  };

  render() {
    let { circles } = this.props.userData;
    if (!circles) {
      circles = {};
    }

    return (
      <div>
        <div className="page-content header-clear-medium">
          <Header title="Eat" subtitle="Get Grub" bg={35} />
          <div className="content top-20">
            <label className="color-theme input-style-1 bottom-10">
              <i className="color-theme fa fa-1x fa-users right-10" />
              Select a group.
            </label>
            <select
              className="select-css"
              onChange={this.handleGroupSelection}
              defaultValue="solo"
            >
              <option value="solo">Eating Solo!</option>
              {Object.keys(circles).map(key => {
                return (
                  <option key={key} value={key}>
                    {circles[key].name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="content top-20">
            <label className="color-theme input-style-1 bottom-10">
              <i className="color-theme fa fa-1x fa-map-marker right-10" />
              Enter city and state or a zip code.
            </label>
            <div className="search-box search-color bg-dark2-dark shadow-tiny  bottom-0">
              <input type="text" onChange={this.handleOnChange} />
            </div>
            {this.state.longitude && this.state.latitude ? (
              <div className="left-5">
                <input
                  id="box2-fac-checkbox"
                  type="checkbox"
                  checked={this.state.useCurrentLocation}
                  onChange={this.handleUseCurrentLocation}
                  className="left-10"
                />
                <label
                  htmlFor="box2-fac-checkbox"
                  className="color-theme"
                  style={{ float: 'left' }}
                >
                  Use current location
                </label>
              </div>
            ) : (
              ''
            )}
            <div>
              <button
                className="button button-m shadow-small button-circle bg-red1-dark top-20"
                onClick={this.handleSearch}
                disabled={
                  !this.state.location && !this.state.useCurrentLocation
                }
              >
                Search
              </button>

              {this.state.data.length > 0 &&
                this.state.selectedGroup !== 'solo' && (
                  <button
                    className="button button-m shadow-small button-circle bg-blue2-dark top-20 left-10"
                    onClick={this.handleCreateOuting}
                    disabled={
                      !this.state.location && !this.state.useCurrentLocation
                    }
                  >
                    Start an Outing
                  </button>
                )}

              {this.state.originalBusinesses.length === -1 && (
                <div>
                  <Toggle
                    name="filterGlutenFree"
                    id="filterGlutenFree"
                    value="on"
                    onChange={this.handleFilterGlutenFree}
                    defaultChecked={false}
                  />
                  <label
                    style={{ float: 'left', marginRight: 10 }}
                    className="color-theme"
                    htmlFor="optOutEmails"
                  >
                    Gluten-free Only
                  </label>
                </div>
              )}
            </div>
            {this.state.isLoading ? (
              <Toast message="Searching" visible={true} type="inProgress" />
            ) : (
              this.getResults(this.state.data)
            )}
          </div>
        </div>
        <ActionSheet
          isOpen={this.state.isActionSheetOpen}
          content={this.getSheetContent()}
          handleClose={this.handleActionSheetClose}
        />
      </div>
    );
  }
}

export default Eat;
