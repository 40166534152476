import React from 'react';

class Toast extends React.Component {
  state = {
    visible: this.props.visible
  };

  render() {
    const { message, type } = this.props;
    let className = 'toast toast-top';
    if (this.state.visible) {
      className = `${className} toast-active`;
    }
    let toastClassName = 'toast-bg opacity-95';
    let iconType = 'fa-check';
    switch (type) {
      case 'success':
        toastClassName = `${toastClassName} bg-green1-dark`;
        break;
      case 'error':
        toastClassName = `${toastClassName} bg-red2-dark`;
        iconType = 'fa-times';
        break;
      case 'info':
        toastClassName = `${toastClassName} bg-blue2-dark`;
        iconType = 'fa-info';
        break;
      case 'inProgress':
        toastClassName = `${toastClassName} bg-blue2-dark`;
        iconType = 'fa-sync fa-spin';
        break;
      default:
        toastClassName = `${toastClassName} bg-green1-dark`;
        iconType = 'fa-check';
    }
    return (
      <div className={className}>
        <p className="color-white">
          <i className={`fa ${iconType} right-10`} /> {message}
        </p>
        <div className={toastClassName} />
      </div>
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.visible !== nextProps.visible) {
      this.setState({
        visible: nextProps.visible
      });
    }
  }
}

export default Toast;
